export const adminOverview = {
    row_1: [
        {
            label: "Total Sales",
            icon: "icon-svg icon-total-sales",
            value_1: "0",
            style_1: "rgb(0, 197, 220) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(0, 197, 220) !important",
            class_2: "fw-light fs-2",
            top_class: "total-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-sales"
        },
        {
            label: "Total Leads",
            icon: "icon-svg icon-total-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "total-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0.00%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "third-party",
            col: "2"
        },
        {
            label: "Total Revenue",
            icon: "icon-svg icon-total-revenue",
            value_1: "0.00",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "total-revenue",
            col: "2"
        }
    ],
    row_2: [
        {
            label: "New Sales",
            icon: "icon-svg icon-new-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "new-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-new-sales"
        },
        {
            label: "New Leads",
            icon: "icon-svg icon-new-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "new-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-new-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0.00%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "new-closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "new-third-party",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0.00",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "new-revenue",
            col: "2"
        }
    ],
    row_3: [
        {
            label: "WIP Sales",
            icon: "icon-svg icon-new-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "new-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-wip-sales"
        },
        {
            is_empty: true,
            col: "2"
        },
        {
            is_empty: true,
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "new-closing-ratio",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0.00",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "new-revenue",
            col: "2"
        }
    ],
    row_4: [
        {
            label: "Renewal Sales",
            icon: "icon-svg icon-renewal-sales",
            value_1: "0",
            class_1: "fw-bold cursor-pointer",
            style_1: "rgb(39, 57, 193) !important",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "renewal-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-renewal-sales"
        },
        {
            label: "Renewal Leads",
            icon: "icon-svg icon-renewal-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "renewal-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-renewal-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0.00%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "renewal-closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "renewal-third-party",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0.00",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "renewal-revenue",
            col: "2"
        }
    ],
    row_5: [
        {
            label: "Lost Lead Renewal Sales",
            icon: "icon-svg icon-llr-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "llr-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-llr-sales"
        },
        {
            label: "Lost Lead Renewal Leads",
            icon: "icon-svg icon-llr-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "llr-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-llr-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0.00%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "llr-closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "llr-third-party",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0.00",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "llr-revenue",
            col: "2"
        }
    ],
    row_6: [
        {
            label: "Lost Leads",
            icon: "icon-svg icon-lrl-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "lrl-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-lost-leads"
        },
        {
            label: "Lead Renewal Lost",
            icon: "icon-svg icon-lrl-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "lrl-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-lrl-leads"
        },
        {
            label: "HL/RHL",
            icon: "icon-svg icon-u-rhl",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "lrl-closing-ratio",
            col: "2"
        },
        {
            label: "WIP",
            icon: "icon-svg icon-u-wip",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "lrl-third-party",
            col: "2"
        },
        {
            is_empty: true,
            col: "2"
        }
    ],
    row_7: [
        {
            label: "Current Year Renewal Sales",
            icon: "icon-svg icon-lr-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "lr-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=lifetime-renewal-leads"
        },
        {
            label: "Previous Year Renewal Leads",
            icon: "icon-svg icon-lr-leads",
            value_1: "6",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "lr-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=lifetime-renewal-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0.00%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "lr-closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "lr-third-party",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0.00",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "lr-revenue",
            col: "2"
        }
    ],
    row_8: [
        {
            label: "Untouched Customers",
            icon: "icon-svg icon-u-customers",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "u-customer",
            col: "2"
        },
        {
            label: "Untouched Renewal Leads",
            icon: "icon-svg icon-u-renewal-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "u-renewal-leads",
            col: "2"
        },
        {
            label: "Untouched New Leads",
            icon: "icon-svg icon-u-new-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "u-new-leads",
            col: "2"
        },
        {
            label: "Untouched LLR",
            icon: "icon-svg icon-u-llr",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "u-llr",
            col: "2"
        }
    ],
    row_9: [
        {
            label: "Total Refund",
            icon: "icon-svg icon-refund",
            value_1: "0 | 0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-refund",
            col: "2"
        },
        {
            label: "Partial Refund",
            icon: "icon-svg icon-partial-refund",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-refund",
            col: "2"
        },
        {
            label: "Full Refund",
            icon: "icon-svg icon-total-refund",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-refund",
            col: "2"
        },
        {
            label: "Total Refund Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0.00",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-refund-amt",
            col: "2"
        },
        {
            label: "Partial Refund Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0.00",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-refund-amt",
            col: "2"
        },
        {
            label: "Full Refund Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0.00",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-full-amt",
            col: "2"
        }
    ],
    row_10: [
        {
            label: "Total Cancellation",
            icon: "icon-svg icon-policies-cancelled",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-cancellation",
            col: "2"
        },
        {
            label: "Partial Cancellation",
            icon: "icon-svg icon-partial-refund",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-cancellation",
            col: "2"
        },
        {
            label: "Full Cancellation",
            icon: "icon-svg icon-total-refund",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-cancellation",
            col: "2"
        },
        {
            label: "Total Cancellation Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0.00",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-cancellation-amt",
            col: "2"
        },
        {
            label: "Partial Cancellation Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0.00",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-cancellation-amt",
            col: "2"
        },
        {
            label: "Full Cancellation Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0.00",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "full-cancellation-amt",
            col: "2"
        }
    ],
    row_11: [
        {
            label: "Total Revenue <span class='pt-1 fs-7'>(Inclusive Addons)</span>",
            icon: "icon-svg icon-total-revenue",
            value_1: "0.00",
            style_1: "rgb(113, 106, 202) !important",
            class_1: "fw-bold",
            top_class: "total-revenue",
            col: "2"
        },
        {
            label: "Addons",
            icon: "icon-svg icon-addson",
            value_1: "0.00",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "addson",
            col: "2"
        }
    ]
}


const statsObject = {
    name: [""],
    count: [""],
    colors: [""]
}

const newTotal = {
    new: statsObject,
    total: statsObject,
}

const newTotalDeleted = {
    new: statsObject,
    total: statsObject,
    deleted: statsObject,
}

const newWipTotal = {
    new: statsObject,
    wip: statsObject,
    total: statsObject,
}

const newRenewalLlr = {
    new: statsObject,
    renewal: statsObject,
    llr: statsObject,
}

const newDeletedRenewalLlr = {
    new: statsObject,
    deleted: statsObject,
    renewal: statsObject,
    llr: statsObject,
}

const newWipRenewalLlr = {
    new: statsObject,
    wip: statsObject,
    renewal: statsObject,
    llr: statsObject,
}

const wip = {
    wip: statsObject
}

const paginations = {
    current_page: 0,
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: null,
    to: 0,
    total: 0
}

export const adminSalesPersonInfo = {
    topSalesPerson: {
        type: "sales-person",
        result: [],
        resultPerformance: []
    },
    salesTargetSummary: [],
    leadTargetSummary: []
}

export const adminStatistics = {
    topProviders: {
        type: 'providers',
        result: [
            {
                policy: "",
                providers: "",
                sold: "",
                revenue: "",
                provider_id: ""
            }
        ]
    },
    topNationality: {
        type: "nationality",
        result: [
            {
                countries: "",
                sold: 0
            }
        ]
    },
    topBrands: {
        type: "brands",
        result: [
            {
                brands: "",
                sold: 0
            }
        ]
    },
    topModels: {
        type: "models",
        result: [
            {
                brands: "",
                model: "",
                sold: 0
            }
        ]
    },
    topDiscounts: {
        type: "discounts",
        result: [
            {
                promotion_code: "",
                code_value_type: 0,
                code_value_type_text: "",
                code_value: 0,
                policies_sold: 0,
                customers: [],
                leads: []
            }
        ]
    },
    getPoliciesSoldWowCoupon: {
        result: {
            policies_sold_with_coupon: 0,
            policies_sold_without_coupon: 0
        },
        type: "policies-sold-wow-coupon"
    },
    policyCreatorStats: [{
        id: 0,
        name: "",
        assigned: 0,
        sold: 0,
        average_policy_time_hours: 0,
        completed: 0,
        cancellation: 0,
        cancellation_amount: 0,
        partial_cancellation: 0,
        partial_cancellation_amount: 0,
        full_cancellation: 0,
        full_cancellation_amount: 0,
        assigned_today: 0,
        assigned_this_month: 0,
        assigned_prev_month: 0,
        overdue: 0,
        refunds: 0,
        assigned_in_month_until_yesterday: 0,
        assigned_prev_months: 0,
        pending_quote_requests: 0
    }],
    policyAgentStats: [{
        id: 0,
        name: "",
        assigned: 0,
        sold: 0,
        average_policy_time_hours: 0
    }],
    leadSourceGraph: {
        // paid_vs_organic_leads: newTotal,
        // paid_vs_organic_deals: newWipTotal,
        // organic_utm_source_leads: newTotal,
        // organic_utm_source_deals: newTotal,
        // paid_utm_source_leads: newTotal,
        // paid_utm_source_deals: newTotal,
        // lead_source_leads: newTotalDeleted,
        // lead_source_deals: newTotal,
        // paid_utm_campaign_leads: newDeletedRenewalLlr,
        // paid_utm_campaign_deals: newWipRenewalLlr,
        // paid_utm_content_leads: newRenewalLlr,
        // paid_utm_content_deals: newWipRenewalLlr,
        // organic_utm_campaign_deals: wip,
        // organic_utm_content_deals: wip,
    }
}

export const adminOtherInfo = {
    userRecentLog: paginations,
    getPolicyData: {
        result: {
            comprehensive_sold_count: 0,
            third_party_sold_count: 0,
            total_sold_count: 0,
            comprehensive_amount_avg: 0,
            third_party_amount_avg: 0,
            comprehensive_amount: 0,
            third_party_amount: 0,
            comprehensive_average_customer_age: 0,
            third_party_average_customer_age: 0
        },
        type: 'policy-data'
    },
    getAverageQuotedVehicle: {
        result: {
            average_car_value: 0
        },
        type: 'average-quoted-vehicle'
    },
    getClosingRatio: {
        result: {
            deals: 0,
            leads: 0,
            closing_ratio: 0
        },
        type: "closing-ratio"
    },
    leadsNew: paginations,
    leadsLost: paginations,
    leadsPending: paginations,
    listOnlineTransactions: paginations,
    listCodTransactions: paginations,
    listDirectTransactions: paginations,
    smsStats: {
        submitted: 0,
        delivered: 0,
        not_delivered: 0,
        total_sent: 0
    }
}

export const dueDateCounts = {
    all: 0,
    last_30_days: 0,
    older_than_30_days: 0,
    today: 0,
    tomorrow: 0,
    upcoming: 0,
    yesterday: 0
}

export const sasSmsTlsDef = {
    topSalesPerson: {
        type: "sales-person",
        result: {
                agent: 0,
                agent_name: "",
                new_leads: 0,
                quick_leads: 0,
                renewal_leads: 0,
                llr_leads: 0,
                total_deals: 0,
                lost_leads: 0,
                lrl_leads: 0,
                total_deals_cancelled: 0,
                deals_cancellation_payable_amount: null,
                pending_leads: 0,
                total_leads: 0,
                leads_this_month: 0,
                new_deals: 0,
                closing_ratio_new: "0.00",
                closing_ratio_total: "0.00",
                untouched_customers: 0,
                untouched_leads: 0,
                over_due_tasks: 0,
                todays_task_count: 0,
                yesterdays_task_count: 0,
                current_month_tasks: 0,
                previous_month_tasks: 0,
                renewal_deals: 0,
                closing_ratio_renewal: "0.00",
                llr_deals: 0,
                closing_ratio_llr: "0.00",
                sales_target: 0,
                revenue_target: "0.00",
                variance: 0,
                total_refund: "0.00",
                cancellation_amount: "0.00",
                total_refund_count: 0,
                cancellation_count: 0,
                total_revenue: "0.00",
                wip_deals: 0,
                revenue_target_achieved: "0.00",
                target_percentage_met: "0.00",
                revenue_target_no_of_days: 0,
                is_target_flag: true
            },
        resultPerformance: []
    },
    getPolicyData: {
        result: {
            comprehensive_sold_count: 0,
            third_party_sold_count: 0,
            total_sold_count: 0,
            comprehensive_amount_avg: 0,
            third_party_amount_avg: 0,
            comprehensive_amount: 0,
            third_party_amount: 0,
            comprehensive_average_customer_age: 0,
            third_party_average_customer_age: 0
        },
        type: "policy-data"
    },
    salesTargetSummary: []

}

export const underwriterDef = {
        id: 0,
        name: 0,
        assigned: 0,
        sold: 0,
        average_policy_time_hours: 0,
        completed: 0,
        cancellation: 0,
        cancellation_amount: 0,
        partial_cancellation: 0,
        partial_cancellation_amount: 0,
        full_cancellation: 0,
        full_cancellation_amount: 0,
        assigned_today: 0,
        assigned_this_month: 0,
        assigned_prev_month: 0,
        overdue: 0,
        refunds: 0,
        assigned_in_month_until_yesterday: 0,
        assigned_prev_months: 0,
        pending_quote_requests: 0
}

export const accountsDef = {
    invoices: {
        online: 0,
        cod: 0,
        direct: 0,
        intransact: 0,
        cod_direct_intransact: 0,
        manual_order: 0,
        premium_discrepancy: 0
    },
    policies: {
        cancellation_approval: 0,
        assigned: 0,
        completed: 0,
        cancelled: 0,
        cancelled_amount: 0,
        cancelled_partial: 0,
        cancelled_partial_amount: 0,
        cancelled_full: 0,
        cancelled_full_amount: 0,
        total_deals: 0,
        total_deals_cancelled: 0,
        total_deals_revenue: 0,
        total_deals_cancelled_revenue: 0
    },
    refunds: {
        pending: 0,
        completed_full: 0,
        completed_full_amount: 0,
        completed_partial: 0,
        completed_partial_amount: 0,
        completed: 0,
        completed_amount: 0,
        completed_closed: 0,
        completed_closed_amount: 0
    }
}

export const marketingAdminDef = {
    paid_vs_organic_leads: newTotal,
    paid_vs_organic_deals: newWipTotal,
    organic_utm_source_leads: newTotal,
    organic_utm_source_deals: newTotal,
    paid_utm_source_leads: newTotal,
    paid_utm_source_deals: newTotal,
    lead_source_leads: newTotalDeleted,
    lead_source_deals: newTotal,
    paid_utm_campaign_leads: newDeletedRenewalLlr,
    paid_utm_campaign_deals: newWipRenewalLlr,
    paid_utm_content_leads: newRenewalLlr,
    paid_utm_content_deals: newWipRenewalLlr,
    organic_utm_campaign_deals: wip,
    organic_utm_content_deals: wip,
}

export const paginationsDef = paginations